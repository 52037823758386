import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import { Go, Layout, LineSwipe, WaveCanvas } from "~components";

const NotFoundPage = () => (
  <>
    <Layout>
      <section
        css={[
          tw`w-screen h-[120vw] md:h-[56.25vw] relative flex items-center justify-center bg-blue-deep text-white`
        ]}
      >
        <WaveCanvas />

        <Grid _css={[tw`z-20`]}>
          <header
            css={[
              css`
                //
              `,
              tw`col-span-12 md:col-span-6 relative flex items-center mt-12 md:mt-4 mb-8`
            ]}
          >
            <T.Head _css={[css``]} font="4" level="2">
              404
            </T.Head>

            <div css={[tw`w-32 relative block ml-5`]}>
              <LineSwipe color="#84bbd9" delay={500} duration={2000} />
            </div>
          </header>

          <section css={[tw`col-span-12`]}>
            <T.Head font="1" level="1">
              Page not found
            </T.Head>

            <T.Body _css={[tw`mt-8`]} font="1" level="1" allowHTML={false}>
              <span>Go </span>

              <Go to="/" _css={[tw`underline`]}>
                home.
              </Go>
            </T.Body>
          </section>
        </Grid>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
